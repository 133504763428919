import React, { ReactElement } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Nav } from 'rsuite'
import { Icon } from '@rsuite/icons'
import {
  FaBuilding,
  FaUser,
  FaCar,
  FaMagic,
  FaWrench,
  FaChartPie,
  FaSearch,
  FaHome
} from 'react-icons/fa'

import { MdOutlineQrCodeScanner } from 'react-icons/md'

import { Inop } from '../../components'

/**
 * Menu items for the admin area
 * @return {ReactElement}
 */
function AdminMenu (): ReactElement {
  const path = useLocation().pathname
  return (
    <>
      <Nav.Item
        to="/admin"
        active={path === '/admin'}
        icon={<Icon as={FaHome} />}
        as={Link}
      >
        Dashboard
      </Nav.Item>
      <Nav.Item
        to="/admin/locations"
        icon={<Icon as={FaCar} />}
        active={path.startsWith('/admin/locations')}
        as={Link}
      >
        Car parks
      </Nav.Item>
      <Nav.Menu
        id="subscriptions-menu"
        title="Subscriptions"
        icon={(
          <Icon as={FaMagic} />
        )}
      >
        <Nav.Item
          to="/admin/subscriptions/pending"
          active={path.startsWith('/admin/subscriptions/pending')}
          as={Link}
        >
          Pending
        </Nav.Item>
        <Nav.Item
          to="/admin/subscriptions/modified"
          active={path.startsWith('/admin/subscriptions/modified')}
          as={Link}
        >
          Amended
        </Nav.Item>
        <Nav.Item
          to="/admin/subscriptions/other"
          active={path.startsWith('/admin/subscriptions/other')}
          as={Link}
        >
          Other
        </Nav.Item>
      </Nav.Menu>
      <Nav.Item
        to="/admin/organisations"
        icon={<Icon as={FaBuilding} />}
        active={path.startsWith('/admin/organisations')}
        as={Link}
      >
        Organisations
      </Nav.Item>
      <Nav.Item
        to="/admin/vouchers"
        icon={<Icon as={MdOutlineQrCodeScanner} />}
        active={path.startsWith('/admin/vouchers')}
        as={Link}
      >
        Vouchers
      </Nav.Item>
      <Nav.Item
        icon={(
          <Icon as={FaUser} />
        )}
        to="/admin/users"
        active={path.startsWith('/admin/users')}
        as={Link}
      >
        Users
      </Nav.Item>
      <Nav.Item
        icon={(
          <Icon as={FaSearch} />
        )}
        to="/admin/vehicles"
        active={path.startsWith('/admin/vehicles')}
        as={Link}
      >
        Vehicle search
      </Nav.Item>
      <Nav.Menu
        id="reporting-submenu"
        title="Reporting"
        icon={(<Icon as={FaChartPie} />)}
      >
        <Nav.Item
          to="/admin/reporting/users"
          active={path.startsWith('/admin/reporting/users')}
          as={Link}
        >
          Users
        </Nav.Item>
        <Nav.Item
          to="/admin/reporting/occupancy"
          active={path.startsWith('/admin/reporting/occupancy')}
          as={Link}
        >
          Occupancy
        </Nav.Item>
        <Nav.Item
          to="/admin/reporting/finance"
          active={path.startsWith('/admin/reporting/finance')}
          as={Link}
        >
          Finance
        </Nav.Item>
        <Nav.Item
          to="/admin/reporting/operations"
          active={path.startsWith('/admin/reporting/operations')}
          as={Link}
        >
          Operations
        </Nav.Item>
      </Nav.Menu>
      <Nav.Item icon={<Icon as={FaWrench} />}><Inop>Maintenance</Inop></Nav.Item>
      <hr />
      <Nav.Item
        to="/"
        icon={<Icon as={FaUser} />}
        as={Link}
      >
        Driver account
      </Nav.Item>
    </>
  )
}

export {
  AdminMenu
}
