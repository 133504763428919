import React, { useEffect, useState } from 'react'
import {
  Form, InputPicker, SelectPicker, DatePicker, Schema
} from 'rsuite'

import type { FormProps } from '../types/form'
import * as schemas from './validation/schemas'
import { Validator } from './validation/validator'
import { ValidatedForm } from '../components'
import { usePaginatedApi } from '../app/hooks'
import { getAdminLocationsForLookup } from '../services/graphql/queries'
import { voucherOptions } from '../features/vouchers/VoucherOptions'
import { VoucherType } from '../types/enums'
import { VoucherFormData } from '../types/voucher'

function VoucherForm (props: Omit<FormProps, 'formValue'> & { formValue: VoucherFormData }) {
  const {
    formValue,
    onChange,
    onSubmit,
    error,
    formId,
  } = props

  const [locations, setLocations] = useState<any[]>([])
  const [phoneDisabled] = useState(!!formValue.phoneNumber)
  const [emailDisabled] = useState(!!formValue.email)

  const locationsQuery = usePaginatedApi({
    query: getAdminLocationsForLookup,
    itemsPerPage: 15,
    fetchParams: {
      organisation: process.env.REACT_APP_ORGANISATION_ID || '',
    },
    queryOptions: {
      cleanUpOnDismount: true,
    },
  })

  // extend location dropdown options when new page is fetched
  useEffect(() => {
    if (!locationsQuery.loading) {
      setLocations([
        ...locations,
        ...(locationsQuery.currentPage || []).map(({ name, id }) => (
          { label: name, value: id }
        )),
      ])
    }
  }, [locationsQuery.loading])

  const validator = new Validator({
    name: {
      model: schemas.required('Please enter a name for the voucher'),
    },
    type: {
      model: schemas.required('Please select a type'),
    },
    location: {
      model: schemas.required('Please select a car park'),
    },
    startDate: {
      model: Schema.Types.DateType()
        .when((schema) => (
          schema.type.value === VoucherType.PERIOD
            ? Schema.Types.DateType().isRequired('Please select a starting date for the voucher')
            : Schema.Types.DateType()
        )),
    },
    expiryDate: {
      model: Schema.Types.DateType().isRequired('Please select a starting date for the voucher'),
    },
    phoneNumber: {
      model: Schema
        .Types
        .StringType()
        .pattern(/[+0]\d+/, 'Please enter a valid phone number, or no number'),
    },
  })

  const loadMoreLocations = () => {
    if (!locationsQuery.loading && locationsQuery.next) {
      locationsQuery.next()
    }
  }

  return (
    <ValidatedForm
      fluid
      id={formId}
      validator={validator}
      formValue={formValue}
      onChange={onChange}
      onSubmit={onSubmit}
      error={error}
      autoComplete="off"
    >
      <Form.Group controlId="name">
        <Form.ControlLabel>Name</Form.ControlLabel>
        <Form.Control name="name" type="text" />
      </Form.Group>
      <Form.Group controlId="type">
        <Form.ControlLabel>Type</Form.ControlLabel>
        <Form.Control name="type" accepter={InputPicker} data={voucherOptions} cleanable={false} />
      </Form.Group>
      <Form.Group controlId="location">
        <Form.ControlLabel>Car park</Form.ControlLabel>
        <Form.Control
          name="location"
          accepter={SelectPicker}
          loading={locationsQuery.loading}
          searchable={false}
          data={locations}
          virtualized
          listProps={{
            onItemsRendered: loadMoreLocations,
          }}
        />
      </Form.Group>
      {
        formValue.type === VoucherType.PERIOD
        && (
          <Form.Group controlId="startDate">
            <Form.ControlLabel>Start date</Form.ControlLabel>
            <Form.Control
              name="startDate"
              accepter={DatePicker}
            />
          </Form.Group>
        )
      }
      <Form.Group controlId="expiryDate">
        <Form.ControlLabel>Expiry date</Form.ControlLabel>
        <Form.Control name="expiryDate" accepter={DatePicker} />
      </Form.Group>
      <Form.Group controlId="phoneNumber">
        <Form.ControlLabel>Recipient mobile number</Form.ControlLabel>
        <Form.Control name="phoneNumber" type="text" disabled={phoneDisabled} />
      </Form.Group>
      <Form.Group controlId="email">
        <Form.ControlLabel>Recipient email</Form.ControlLabel>
        <Form.Control name="email" type="text" disabled={emailDisabled} />
      </Form.Group>
    </ValidatedForm>
  )
}

export {
  VoucherForm
}
