/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement } from 'react'
import { Table, Tag } from 'rsuite'
/* eslint-disable import/no-unresolved */
import { formatInTimeZone } from 'date-fns-tz'

import { RowData, TableDataRenderCallback } from '../types/table'

type TableDataProps<Row> = {
  dataKey: string,
  content?: TableDataRenderCallback<Row>,
  format?: 'date'|'money'|'tag'|'datetime',
  /* The following props are populated by Rsuite and will not be passed from the higher component
     directly. However, TypeScript doesn't know this, and so they must be declared as optional, with
     default values set in the component below */
  rowData?: Row,
  rowIndex?: number,
}

/**
 * Render a table cell with custom content
 * @param {Object} props
 * @generic Row generic defines the returned data for each individual row
 * @return {ReactElement}
 */
function TableData<Row extends RowData = RowData> (props: TableDataProps<Row>): ReactElement {
  const {
    dataKey, rowData = {} as Row, rowIndex = 0, content, format, ...others
  } = props

  let cellData = rowData[dataKey]

  if (content) {
    cellData = content(rowData, rowIndex)
  }
  if (format === 'date') {
    cellData = cellData ? (new Date(cellData)).toLocaleDateString() : '-'
  }
  if (format === 'money') {
    cellData = `$${Number(cellData).toFixed(2)}`
  }
  if (format === 'tag') {
    cellData = <Tag>{cellData}</Tag>
  }
  if (format === 'datetime') {
    cellData = cellData ? formatInTimeZone(new Date(cellData), 'Australia/Hobart', 'dd/MM/yyyy hh:mm') : '-'
  }

  return (
    <Table.Cell {...others}>
      {cellData}
    </Table.Cell>
  )
}

export {
  TableData
}
